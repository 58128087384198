const Divider = (props) => {
  return (
    <div
      style={{
        borderBottom: '3px solid #67af89',
        margin: '20px 20px',
        width: '100vw',
      }}
    />
  );
};

export default Divider;
